import * as React from "react"
import Auth from '../components/auth'

const Index = () => {
  return (
    <Auth/>
  )
}

export default Index
